import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CompanyPageRoutingModule } from './company-routing.module';
import { CompanyPage } from './company.page';
import { TableModule } from 'primeng/table';
import { MessageModule } from 'primeng/message';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { PaginatorModule } from 'primeng/paginator';
import { PickListModule } from 'primeng/picklist';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CompanyPageRoutingModule,
    ToastModule,
    DialogModule,
    AccordionModule,
    PickListModule,
    ConfirmPopupModule,
    MessageModule,
    TableModule,
    DropdownModule,
    InputNumberModule,
    CalendarModule,
    FileUploadModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    ReactiveFormsModule,
    SelectButtonModule,
    ConfirmDialogModule,
    PaginatorModule,
    AutoCompleteModule,
    MessagesModule,
    TooltipModule,
  ],
  providers: [ConfirmationService, MessageService, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [CompanyPage],
})
export class CompanyPageModule {}
