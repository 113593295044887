export const userTypes = [
  {
    label: 'Cliente',
    value: 'CUSTOMER',
  },

  {
    label: 'Proveedor',
    value: 'SUPPLIER',
  },
  {
    label: 'Empleado',
    value: 'EMPLOYEE',
  },
  {
    label: 'Asociado',
    value: 'NONE',
  },
];

export const YesOrNo = [
  {
    label: 'Sí',
    value: 1,
  },
  {
    label: 'No',
    value: 0,
  },
];

export const crudOptionsCompanies = [
  {
    label: 'Editar',
    value: 1,
  },
  {
    label: 'Inventario',
    value: 2,
  },
  {
    label: 'Ciclo de vida (LCI)',
    value: 3,
  },
  {
    label: 'Gestionar departamentos',
    value: 4,
  },
  {
    label: 'Gestionar usuarios',
    value: 5,
  },
  {
    label: 'Eliminar',
    value: 6
  }
];

export const crudOptionsProducts = [
  {
    label: 'Seleccione una opción',
    value: 0,
  },
  {
    label: 'Ver producto',
    value: 1,
  },
  {
    label: 'Editar',
    value: 2,
  },
  {
    label: 'Eliminar',
    value: 3
  }
];

export const departmentOptions = [
  {
    label: 'Nuevo departamento',
    value: 'NEW',
  },
  {
    label: 'Departamentos existentes',
    value: 'LIST',
  },
];
