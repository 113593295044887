import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { User } from 'src/app/models/user';
import { confirmPassword } from 'src/app/utils';
import { Utils } from 'src/app/app.utils';
import { userTypes } from 'src/app/app.const';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Department } from 'src/app/models/department';
import { DatePipe } from '@angular/common';
import { Company } from 'src/app/models/company';
import { firstValueFrom, concatMap, of } from 'rxjs';
import { Section } from 'src/app/models/section';

@Component({
  selector: 'app-user',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit {
  users: User[] = [];
  msgs: Message[] = [];
  loading: boolean = true;
  dialogUser: boolean = false;
  dialogUserType: boolean = false;
  userSelected: User;
  edit: boolean = false;
  formSubmitted: boolean = false;
  mainPhoto: File | undefined;
  mainStringPhoto: any;
  loadingForm: boolean = false;
  formUser: FormGroup;
  formCustomer: FormGroup;
  formEmployee: FormGroup;
  formSupplier: FormGroup;
  userTypes: any[] = []; //userTypes
  userTypeSelected: any = false;
  typeSelected: boolean = false;
  userTypesAsociate: string = '';
  countries: any[] = [];
  departments: Department[] = [];
  filteredUsers: any[] | undefined;
  uploadedFile: File | null = null;
  filteredDepartments: any[] | undefined;
  dialogLinkCompanies: boolean = false;
  companies: Company[] = [];
  selectedCompanies: Company[] = [];
  dialogAddSections: boolean = false;
  dialogViewSections: boolean = false;
  step1: boolean = false;
  step2: boolean = false;
  sections: Section[] = [];
  selectedSection: number = 0;
  permissions: any[] = [];
  selectedPermissions: any[] = [];
  selectedUser: User | null;
  selectedCompany: Company | null;
  companiesByUser: any[] = [];
  responseCompanyByUser: any;
  flagCompany: boolean = false;
  transformedSections: any[] | null = [];
  roleTypes: any[] = [
    {
      label: 'Administrador',
      value: 'administrador',
    },
    {
      label: 'Reportador',
      value: 'reportador',
    },
    {
      label: 'Auditor',
      value: 'auditor',
    }    
  ];
  roleTypeSelected: string = '';
  rolSelected: boolean = false;
  loadingSavePermissionByRol: boolean = false;
  loadingSelectCompany: boolean = false;
  loadingAddUserToCompany: boolean = false;
  modalAddUserToCompany: boolean = false;
  checkUserByCompany: any = false;
  checkPermissionsByUser: any = false;
  allDepartments: any = false;
  selectedDepartment: any = false;
  loadingDepartments: boolean = false;
  boolAzure: boolean = false;
  userLogin: any;

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private apiService: ApiService
  ) {}

  async ngOnInit() {
    this.formUser = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.minLength(8)],
      repeat_password: [''],
    });

    this.formCustomer = this.fb.group({
      user: ['', Validators.required],
      responsible: ['', Validators.required],
      entry_date: [''],
      address: [''],
      zip_code: [''],
      city: [''],
      province: [''],
      country: [''],
      criticality: [''],
      document: [''],
      document_hash: [''],
    });

    this.formEmployee = this.fb.group({
      user: ['', Validators.required],
      department: [''],
      responsible: [''],
      entry_date: [''],
      document: [''],
      document_hash: [''],
    });

    this.formSupplier = this.fb.group({
      user: ['', Validators.required],
      responsible: [''],
      entry_date: [''],
      address: [''],
      zip_code: [''],
      city: [''],
      province: [''],
      country: [''],
      criticality: [''],
      document: [''],
      document_hash: [''],
    });
    this.getUsers();
    this.getPermissions();
    this.countries = Utils.getCountries();
    await this.getCurrentUser();
  }

  /**
   * Obtener usuario actual
   */
  async getCurrentUser() {
    try {
      const userResponse = await firstValueFrom(this.apiService.getData('/api/user'));
      if(userResponse){
        this.userLogin = userResponse;
      }
    } catch (err) {
      console.error(
        'Error al obtener datos del usuario',
        err
      );
    } 
  }  

  /**
   * Obtener listado de permisos registrados
   */
  getPermissions() {
    this.apiService.getData('/api/permissions').subscribe({
      next: (response: any) => {
        if (response.data) {
          this.permissions = response.data;
        }
      },
      error: (err) => {
        console.error('Error al cargar listado de permisos', err);
      },
    });
  }

  /**
   * Cargar listado de usuarios registrados
   */
  getUsers() {
    this.apiService.getData('/api/users').subscribe({
      next: (response: any) => {
        if (response.data) {
          this.users = response.data;
          this.loading = false;
        }
      },
      error: (err) => {
        this.loading = false;
        console.error('Error al cargar listado de usuarios', err);
      },
    });
  }

  /**
   * Manejador de alerta para eliminar usuario
   * @param userId
   */
  onDeleteClick(event, userId: string) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que deseas eliminar este usuario?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.apiService.deleteData(`/api/users/${userId}`).subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Usuario eliminado correctamente',
              });
              this.getUsers();
            }
          },
          error: (error) => {
            console.error('Error al eliminar el usuario', error);
          },
        });
      },
    });
  }

  /**
   * Manejador de modal de asignación de usuario a empresas
   * @param userId
   */
  onAssignCompanyClick(user: User) {
    this.dialogLinkCompanies = true;
    this.userSelected = user;
  }

  /**
   * Manejador de modal de usuario
   * @param user
   */
  showUserDialog(user?: User) {
    if (user) {
      this.formUser.patchValue(user);
      this.userSelected = user;
      this.mainStringPhoto = user.image;
    }
    this.apiService.getAzureEnv().subscribe((data) => {
      this.boolAzure = data ? true : false;
      this.edit = user ? true : false;
      this.dialogUser = true;
    });
  }

  /**
   * Manejador de cierre de modal de formulario general de usuario
   */
  closeUserModal() {
    this.boolAzure = false;
    this.edit = false;
    this.formUser.reset();
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }

  /**
   * Manejador de cierre de modal de formulario según tipo de usuario
   */
  closeUserTypeModal() {
    this.edit = false;
    this.formCustomer.reset();
    this.formEmployee.reset();
    this.formSupplier.reset();
    this.userTypeSelected = false;
  }

  /**
   * Envío de formulario general de usuario
   * @returns
   */
  onSubmitUser() {
    this.formUser.markAllAsTouched();
    if (this.formUser.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    // Procesamos datos del formulario como FormData
    this.formSubmitted = true;
    this.loadingForm = true;
    const formValue = this.formUser.value;
    const formData = new FormData();

    if (this.mainPhoto) {
      formData.append('image', this.mainPhoto);
    }
    formData.append('name', formValue.name.toString());
    formData.append('email', formValue.email.toString());
    if (formValue.password) {
      formData.append('password', formValue.password.toString());
    }

    if (formValue.repeat_password) {
      formData.append('passwordConfirm', formValue.repeat_password.toString());
    }

    if (this.edit) {
      this.apiService
        .updateData('/api/users/' + this.userSelected.id, formData)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Usuario registrado correctamente',
              });
              this.loadingForm = false;
              this.dialogUser = false;
              this.getUsers();
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al actualizar el usuario', error);
            if (error.status === 422) {
              const message = Utils.showFormErrorMessage(error);
              return this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail: message,
              });
            }
          },
        });
    } else {
      this.apiService.sendData('/api/users', formData).subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Usuario actualizado correctamente',
            });
            this.loadingForm = false;
            this.dialogUser = false;
            this.getUsers();
          }
        },
        error: (error) => {
          this.loadingForm = false;
          console.error('Error al registrar el usuario', error);
          if (error.status === 422) {
            const message = Utils.showFormErrorMessage(error);
            return this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail: message,
            });
          }
        },
      });
    }
  }

  /**
   * Manejador para imágen principal
   * @param $event
   * @returns
   */
  public handleUploadPhoto($event: any) {
    try {
      const files: FileList = $event.target.files;
      this.mainPhoto = Utils.renameFile(
        files[0],
        new Date().getTime().toString()
      );
      var reader = new FileReader();
      if (
        this.mainPhoto.type === 'image/jpeg' ||
        this.mainPhoto.type === 'image/png'
      ) {
        reader.onloadend = async () => {
          $event.target.value = '';
          this.mainStringPhoto = String(reader.result);
        };
        reader.readAsDataURL(this.mainPhoto);
      } else {
      }
    } catch (error) {
      console.error('Error al manejar imagen adjunta', error);
    }
  }

  /**
   * Eliminar foto adjunta al formulario
   */
  public deletePhoto() {
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }

  /**
   * Manejador del modal de asignación de tipo de usuario y el formulario que corresponda
   * @param userId
   */
  handleUserType(user: User) {
    this.userSelected = user;
    this.dialogUserType = true;
    if (
      user.customers.length > 0 ||
      user.suppliers.length > 0 ||
      user.employees.length > 0
    ) {
      this.edit = true;
    }
  }

  /**
   * Filtro de usuarios para desplegable
   * @param event
   */
  filterUser(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.users as any[]).length; i++) {
      let user = (this.users as any[])[i];
      if (user.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }

    this.filteredUsers = filtered;
  }

  /**
   * Filtro de usuarios para desplegable
   * @param event
   */
  filterDepartment(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.departments as any[]).length; i++) {
      let department = (this.departments as any[])[i];
      if (
        department.main_activity.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(department);
      }
    }

    this.filteredDepartments = filtered;
  }

  /**
   * Manejador para el selector de documentos
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Manejador de cierre de modal de asignación a empresas
   */
  closeLinkToCompaniesModal() {
    this.selectedCompanies = [];
    this.dialogLinkCompanies = false;
  }

  /**
   * Enviar formulario de asociación de empresas al usuario seleccionado
   */
  linkUserToCompanies() {
    if (this.selectedCompanies.length === 0) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    this.loadingForm = true;

    this.apiService
      .sendData(
        `/api/users/${this.userSelected.id}/companies/${this.selectedCompanies}`
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Empresas asignadas correctamente',
            });
            this.closeLinkToCompaniesModal();
          }
        },
        error: (error) => {
          this.loadingForm = false;
          console.error('Error al asignar empresas al usuario', error);
        },
      });
  }

  /**
   * Manejador para seleccionar opción de añadir o ver permisos de secciones
   * @param event
   * @param user
   */
  handleClickPermissionByUser(event, user: User) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Gestión de permisos en secciones',
      message: 'Seleccione una opción',
      acceptLabel: 'Añadir nuevos permisos',
      rejectLabel: 'Ver permisos existentes',
      accept: () => {
        this.handleAddSectionPermissionsByUser(user);
      },
      reject: () => {
        this.handleUserPermissions(user);
      },
    });
  }

  /**
   * Manejador de modal de inicio de asignación de permisos de secciones a un usuario
   * @param user
   */
  handleAddSectionPermissionsByUser(user: User) {
    // Obtenemos detalles de usuario seleccionado y encadenado las secciones disponibles
    this.apiService
      .getData(`/api/users/${user.id}`)
      .pipe(
        concatMap((response) => {
          // Me aseguro que la segunda llamada se hace si y solo si la primera acaba con concatMap
          if (response.data) {
            this.selectedUser = response.data;
            return this.apiService.getData(`/api/sections`);
          }
          return of(null); // Si response.data es vacio, devolvemos null
        })
      )
      .subscribe({
        next: (response) => {
          if (response) {
            if (response.data) {
              this.sections = response.data;
              this.companiesByUser = this.getCompaniesByUser();
              this.dialogAddSections = true;
            } else {
              return this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail:
                  'Debe crear al menos una sección para realizar esta acción',
              });
            }
          }
        },
        error: (err) => {
          console.error('Error al guardar el indicador con Excel:', err);
        },
      });
  }

  /**
   * Extraer compañias de un usuario según un usuario seleccionado
   */
  getCompaniesByUser() {
    const companiesMap = new Map<number, Company>();

    this.selectedUser?.employees.forEach((employee) => {
      if (
        employee.department &&
        employee.department.companies &&
        !companiesMap.has(employee.department.companies.id)
      ) {
        companiesMap.set(
          employee.department.companies.id,
          employee.department.companies
        );
      }
    });

    this.selectedUser?.suppliers.forEach((supplier) => {
      if (supplier.company && !companiesMap.has(supplier.company.id)) {
        companiesMap.set(supplier.company.id, supplier.company);
      }
    });

    this.selectedUser?.customers.forEach((customer) => {
      if (customer.company && !companiesMap.has(customer.company.id)) {
        companiesMap.set(customer.company.id, customer.company);
      }
    });

    this.selectedUser?.companies.forEach((item) => {
      if (item.id && !companiesMap.has(item.id)) {
        companiesMap.set(item.id, item);
      }
    });

    return Array.from(companiesMap.values());
  }

  /**
   * Manejador de cierre de modal de asignación de usuario a secciones
   */
  closeSectionsModal() {
    this.selectedPermissions = [];
    this.selectedSection = 0;
    this.companiesByUser = [];
    this.selectedCompany = null;
    this.selectedUser = null;
    this.flagCompany = false;
    this.responseCompanyByUser = null;
    this.userTypes = [];
    this.userTypeSelected = false;
    this.typeSelected = false;
    this.selectedDepartment = false;
    this.checkUserByCompany = false;
    this.checkPermissionsByUser = false;
  }

  /**
   * Enviar formulario de asociación de usuario con sección y permisos
   */
  onSubmitUserPermissions() {
    if (this.selectedPermissions.length === 0) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar al menos un permiso del desplegable',
      });
    }

    const permissionsId = JSON.stringify(this.selectedPermissions);

    this.apiService
      .sendData(
        `/api/companies/${this.selectedCompany}/users/${this.selectedUser?.id}/sections/${this.selectedSection}/permissions/${permissionsId}`
      )
      .subscribe({
        next: (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Registros guardados correctamente',
          });
          this.dialogAddSections = false;
        },
        error: (error) => {
          console.error(
            'Error al guardar permisos de un usuario y una sección',
            error
          );
        },
      });
  }

  /**
   * Visualizar y gestionar permisos de secciones ya registrados
   */
  handleUserPermissions(user: User) {
    // Obtenemos detalles de usuario seleccionado y encadenado las secciones disponibles
    this.apiService
      .getData(`/api/users/${user.id}`)
      .pipe(
        concatMap((response) => {
          // Me aseguro que la segunda llamada se hace si y solo si la primera acaba con concatMap
          if (response.data) {
            this.selectedUser = response.data;
            return this.apiService.getData(`/api/sections`);
          }
          return of(null); // Si response.data es vacio, devolvemos null
        })
      )
      .subscribe({
        next: (response) => {
          if (response) {
            if (response.data) {
              this.sections = response.data;
              this.companiesByUser = this.getCompaniesByUser();
              this.dialogViewSections = true;
            } else {
              return this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail:
                  'Debe crear al menos una sección para realizar esta acción',
              });
            }
          }
        },
        error: (err) => {
          console.error('Error al guardar el indicador con Excel:', err);
        },
      });
  }

  /**
   * Manejador de cierre de modal de gestión de permisos de secciones
   */
  closeRegisteredSectionsModal() {
    this.selectedCompany = null;
    this.transformedSections = null;
    this.responseCompanyByUser = [];
  }

  /**
   * Manejador de evento de selección en desplegable de empresas para visualizar permisos de secciones
   */
  handleSelectedCompany(event) {
    //reset variables
    this.userTypes = [];
    this.userTypeSelected = false;
    this.typeSelected = false;
    this.selectedDepartment = false;
    this.checkUserByCompany = false;
    this.checkPermissionsByUser = false;
    this.userTypesAsociate = '';
    this.rolSelected = false;
    this.roleTypeSelected = '';
    //check userSelected para roleTypes
    if(this.selectedUser?.id === 1){
      //es id=1, puede darse permisos de superadministrador
      if(this.roleTypes.length === 3){
        //add a primera posición
        this.roleTypes.unshift(
          {
            label: 'Superadministrador',
            value: 'superadmin',
          }
        );
      }
    }else{
      //es otro usuario, no puede dar permisos de superadministrador
      if(this.roleTypes.length > 3){
        //del primera posición
        this.roleTypes.shift();
      }
    }
    //icon load
    this.loadingSelectCompany = true;
    this.selectedCompany = event.value;
    // getUserData by selectCompany
    this.apiService
      .getData(
        `/api/companies/${this.selectedCompany}/users/${this.selectedUser?.id}`
      )
      .subscribe({
        next: (response) => {
          this.flagCompany = true;
          this.responseCompanyByUser = response.data;
          if (this.responseCompanyByUser) {
            //check user in company
            if (
              this.responseCompanyByUser.company === null &&
              this.responseCompanyByUser.employee === null &&
              this.responseCompanyByUser.customer === null &&
              this.responseCompanyByUser.supplier === null
            ) {
              //not exists
              this.checkUserByCompany = false;
              this.checkPermissionsByUser = false;
              this.userTypes = [
                { label: 'Cliente', value: 'CUSTOMER' },
                { label: 'Proveedor', value: 'SUPPLIER' },
                { label: 'Empleado', value: 'EMPLOYEE' },
                { label: 'Asociado', value: 'NONE' },
              ];
            } else {
              //exists
              this.responseCompanyByUser.customer.length !== 0
                ? (this.userTypesAsociate += ' cliente')
                : this.userTypes.push({ label: 'Cliente', value: 'CUSTOMER' });
              this.responseCompanyByUser.supplier.length !== 0
                ? (this.userTypesAsociate += ' proveedor')
                : this.userTypes.push({
                    label: 'Proveedor',
                    value: 'SUPPLIER',
                  });
              this.responseCompanyByUser.employee.length !== 0
                ? (this.userTypesAsociate += ' empleado')
                : this.userTypes.push({ label: 'Empleado', value: 'EMPLOYEE' });
              this.responseCompanyByUser.company.length !== 0
                ? (this.userTypesAsociate += ' asociado')
                : this.userTypes.push({ label: 'Asociado', value: 'NONE' });
              //
              this.checkUserByCompany = this.responseCompanyByUser;
              //check permissions in company
              if (this.checkUserByCompany.sections === null) {
                //not exists
                this.checkPermissionsByUser = false;
              } else {
                //exists
                this.checkPermissionsByUser = this.checkUserByCompany.sections;
              }
            }
          }
          //console.log("Tipo de usuario: ",this.userTypes)
          //console.log("Pertenece a una empresa: ", this.checkUserByCompany, "Tiene permisos en la empresa: ", this.checkPermissionsByUser)
          this.loadingSelectCompany = false;
          this.transformSections();
        },
        error: (err) => {
          console.error('Error al obtener los datos', err);
        },
      });
  }

  /**
   * Manejador de eliminación de permiso de un usuario en sección
   */
  onDeletePermissionClick(event, section: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este permiso?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.apiService
          .deleteData(
            `/api/companies/${this.selectedCompany}/users/${this.selectedUser?.id}/sections/${section?.sectionId}/permissions/${section?.permissionId}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Permiso eliminado correctamente',
                });
              }
              this.handleSelectedCompany({ value: this.selectedCompany });
            },
            error: (error) => {
              console.error('Error al eliminar el permiso', error);
            },
          });
      },
    });
  }

  /**
   * Función auxiliar para formar los datos de secciones con sus permisos según la empresa y usuario seleccionados
   */
  transformSections() {
    const sections = this.responseCompanyByUser['sections'];

    if (!sections || sections.length === 0) {
      // Si sections es null, undefined, o un array vacío, asignar un array vacío
      this.transformedSections = [];
      return;
    }

    // Aplanar objeto para tenerlo en un array de objetos de un nivel solamente
    this.transformedSections = sections.flatMap((section) => {
      return section.permissions.map((permission) => {
        return {
          sectionId: section.id,
          sectionName: section.name,
          permissionId: permission.id,
          permissionName: permission.name,
        };
      });
    });
  }

  /**
   * Manejador confirmacion para dar permisos por rol a un usuario
   */
  handleClickAddPermissionByRoleUser(event, roleTypeSelected) {
    if (roleTypeSelected !== null) {
      this.rolSelected = true;
      //console.log(roleTypeSelected);
    } else {
      this.rolSelected = false;
      this.roleTypeSelected = '';
    }
  }

  /**
   * guardar permisos por rol para un usuario
   */
  async savePermissionByRoleUser(roleTypeSelected: string) {
    this.loadingSavePermissionByRol = true; //show icon load
    //save permissions
    this.apiService
      .sendData(
        `/api/company/${this.selectedCompany}/user/${this.selectedUser?.id}/role/${roleTypeSelected}`
      )
      .subscribe({
        next: async (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Permisos guardados correctamente',
          });

          this.loadingSavePermissionByRol = false; //hidden icon load
          this.dialogAddSections = false; //close modal

          // Enviar email al usuario solo si is_sent de User está a false
          await this.currentUserSelected(this.selectedUser).then((response) => {
            const user = response.data;
            if (response.data.is_sent === 0) {
              this.apiService.getAzureEnv().subscribe((data) => {
                this.boolAzure = data ? true : false;

                let datosEmail = {
                  name: user.name,
                  email: user.email,
                  isAzure: this.boolAzure,
                };

                this.apiService
                  .sendData(`/api/email/accessCredentials`, datosEmail)
                  .subscribe({
                    next: (response) => {},
                    error: (error) => {
                      console.error(
                        'Error al enviar el email de credenciales al usuario',
                        error
                      );
                    },
                  });
              });
            }
          });
        },
        error: (error) => {
          console.error(
            'Error al guardar permisos de usuario en section: ',
            error
          );
        },
      });
  }

  /**
   * Obtener usuario seleccionado
   */
  async currentUserSelected(user) {
    return await firstValueFrom(
      this.apiService.getData(`/api/users/${user.id}`)
    );
  }

  /**
   * Iniciar el proceso de añadir usuario a empresa
   */
  async addUserToCompany(user) {
    this.loadingAddUserToCompany = true;
    
    try {
      //obtener el usuario seleccionado
      let getUSer = await this.currentUserSelected(user);
      this.selectedUser = getUSer.data; 
      //obtenemos el listado de empresas
      this.apiService.getData(`/api/companies`).subscribe({
        next: (response) => {
          this.companies = response.data;
          this.modalAddUserToCompany = true;
          this.loadingAddUserToCompany = false;
        },
        error: (error) => {
          console.error('Error al obtener el listado de empresas: ', error);
        },
      });
    } catch (error) {
      console.error('Error al obtener el usuario:', error);
    }
  }

  /**
   * Obtener listado de departamentos
   */
  async getDepartments() {
    return await firstValueFrom(
      this.apiService.getData(
        `/api/companies/${this.selectedCompany}/departments`
      )
    );
  }

  /**
   * obtener tipo de usuario seleccionado
   */
  async selectUserType(event, userTypeSelected) {
    this.loadingDepartments = true;
    this.selectedDepartment = false;
    if (userTypeSelected !== null) {
      this.typeSelected = true;
      if (this.userTypeSelected === 'EMPLOYEE') {
        try {
          let departmentIndex = await this.getDepartments();
          this.allDepartments = departmentIndex.data;
          this.loadingDepartments = false;
        } catch (error) {
          console.error('Error al obtener los departamentos:', error);
        }
      }
      this.loadingDepartments = false;
    } else {
      this.typeSelected = false;
      this.userTypeSelected = false;
    }
  }

  /**
   * guarda en BBDD segun el tipo de usuario seleccionado
   */
  saveUserToCompany() {
    if (this.userTypeSelected === 'EMPLOYEE') {
      if (!this.selectedDepartment) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar un departamento',
        });
      }
    }
    this.loadingAddUserToCompany = true;

    let url: string = '',
      data: any;
    switch (this.userTypeSelected) {
      case 'NONE': //asociado
        data = '';
        url = `/api/companies/${this.selectedCompany}/users/[${this.selectedUser?.id}]`;
        break;
      case 'CUSTOMER': //cliente
        data = [{ user_id: this.selectedUser?.id }];
        url = `/api/companies/${this.selectedCompany}/customers`;
        break;
      case 'SUPPLIER': //proveedor
        data = [{ user_id: this.selectedUser?.id }];
        url = `/api/companies/${this.selectedCompany}/suppliers`;
        break;
      case 'EMPLOYEE': //empleado
        data = [
          {
            user_id: this.selectedUser?.id,
            department_id: this.selectedDepartment,
          },
        ];
        url = `/api/companies/${this.selectedCompany}/employees`;
        break;
    }
    //guardamos los datos en BBDD
    this.apiService.sendData(url, data).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'OK',
          detail: 'Usuario asociado a la empresa correctamente',
        });
        this.modalAddUserToCompany = false;
        this.loadingAddUserToCompany = false;
      },
      error: (error) => {
        console.error('Error al asociar el usuario a la empresa: ', error);
      },
    });
  }
}
