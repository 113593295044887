import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { Department } from 'src/app/models/department';
import { User } from 'src/app/models/user';
import { Company } from 'src/app/models/company';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.page.html',
  styleUrls: ['./departments.page.scss'],
})
export class DepartmentsPage implements OnInit {
  departments: Department[] = [];
  loading: boolean = true;
  dialogDepartment: boolean = false;
  departmentsSelected: Department;
  edit: boolean = false;
  formSubmitted: boolean = false;
  form: FormGroup;
  loadingForm: boolean = false;
  uploadedFile: File | null = null;
  users: User[] = [];
  companies: Company[] = [];
  filteredCompanies: any[] | undefined;
  filteredUsers: any[] | undefined;

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private apiService: ApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      company: ['', Validators.required],
      responsible: [''],
      main_activity: ['', Validators.required],
      date: ['', Validators.required],
      tasks_functions: ['', Validators.required],
      structure: ['', Validators.required],
      comments: ['', Validators.required],
      document: [''],
      document_hash: [''],
    });
    this.getUsers();
    this.getCompanies();
    this.getDepartments();
  }

  /**
   * Obtener listado de usuario guardados
   */
  getUsers() {
    this.apiService.getData('/api/users').subscribe({
      next: (response) => {
        this.users = response.data;
      },
      error: (error) => {
        console.error('Error al obtener el listado de usuarios', error);
      },
    });
  }

  /**
   * Obtener listado de empresas guardadas
   */
  getCompanies() {
    this.apiService.getData('/api/companies').subscribe({
      next: (response) => {
        this.companies = response.data;
      },
      error: (error) => {
        console.error('Error al obtener el listado de usuarios');
      },
    });
  }

  /**
   * Manejador de modal de creación/edición de departamentos
   */
  showDepartmentDialog(department?: Department) {
    if (department) {
      this.form.patchValue({
        ...department,
        date: new Date(department.date),
        company: department.companies,
      });

      this.departmentsSelected = department;
    }
    this.edit = department ? true : false;
    this.dialogDepartment = true;
  }

  /**
   * Obtenención de listado de departamentos registrados
   */
  getDepartments() {
    this.apiService.getData('/api/departments').subscribe({
      next: (response) => {
        this.departments = response.data;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error al obtener los departamentos', error);
        this.loading = false;
      },
    });
  }

  /**
   * Manejador para eliminación de departamento según su id
   * @param departmentId
   */
  onDeleteClick(event, departmentId: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este departamento?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.apiService
          .deleteData(`/api/departments/${departmentId}`)
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Usuario eliminado correctamente',
                });
                this.getDepartments();
              }
            },
            error: (error) => {
              console.error('Error al eliminar el usuario', error);
            },
          });
      },
    });
  }

  /**
   * Manejador de cierre de modal de departamento
   */
  closeDepartmentModal() {
    this.edit = false;
    this.form.reset();
  }

  /**
   * Manejador para el selector de documentos
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Filtro de compañías para desplegable
   * @param event
   */
  filterCompany(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.companies as any[]).length; i++) {
      let company = (this.companies as any[])[i];
      if (
        company.company_name.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(company);
      }
    }

    this.filteredCompanies = filtered;
  }

  /**
   * Filtro de usuarios para desplegable
   * @param event
   */
  filterUser(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.users as any[]).length; i++) {
      let user = (this.users as any[])[i];
      if (user.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }

    this.filteredUsers = filtered;
  }

  /**
   * Enviar formulario de departamento
   */
  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    // Procesar formulario a FormData
    const formValue = this.form.value;
    const formData = new FormData();

    formData.append('company_id', formValue.company.id.toString());
    if (formValue.responsible) {
      formData.append('responsible', formValue.responsible.id.toString());
    }
    formData.append('main_activity', formValue.main_activity.toString());

    if (formValue.date) {
      // Formateo la fecha a formato date para leerlo en BBDD
      const selectedDate = formValue.date;

      // Si transformedDate es null devuelve cadena vacia
      const transformedDate =
        this.datePipe.transform(selectedDate, 'yyyy-MM-dd') ?? '';
      formData.append('date', transformedDate);
    }

    formData.append('tasks_functions', formValue.tasks_functions.toString());
    formData.append('structure', formValue.structure.toString());
    formData.append('comments', formValue.comments.toString());
    if (formValue.document) {
      formData.append('document', formValue.document);
    }

    this.formSubmitted = true;
    this.loadingForm = true;

    // Enviamos datos para creación o edición
    if (this.edit) {
      this.apiService
        .updateData('/api/departments/' + this.departmentsSelected.id, formData)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Departamento actualizado correctamente',
              });
              this.loadingForm = false;
              this.dialogDepartment = false;
              this.getDepartments();
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al actualizar el departamento', error);
          },
        });
    } else {
      this.apiService.sendData('/api/departments', formData).subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Departamento guardado correctamente',
            });
            this.loadingForm = false;
            this.dialogDepartment = false;
            this.getDepartments();
          }
        },
        error: (error) => {
          this.loadingForm = false;
          console.error('Error al registrar el departamento', error);
        },
      });
    }
  }
}
