import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SectionsPageRoutingModule } from './sections-routing.module';

import { SectionsPage } from './sections.page';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    DialogModule,
    ToastModule,
    MessageModule,
    ReactiveFormsModule,
    ButtonModule,
    AutoCompleteModule,
    TooltipModule,
    ConfirmPopupModule,
    InputTextModule,
    IonicModule,
    SectionsPageRoutingModule,
  ],
  declarations: [SectionsPage],
  providers: [MessageService, ConfirmationService, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SectionsPageModule {}
