<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<div class="p-grid">
  <div class="p-col-12">
    <h2>Departamentos</h2>
  </div>
  @if(loading){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  } @else {
  <div class="p-col-12 mb-4 custom-align-right">
    <button
      pButton
      type="button"
      label="Crear departamento"
      icon="pi pi-plus"
      (click)="showDepartmentDialog()"
    ></button>
  </div>

  @if(departments && departments.length > 0){
  <div class="p-col-12">
    <p-table [value]="departments" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th>Empresa</th>
          <th>Responsable</th>
          <th>Actividad principal</th>
          <th>Fecha</th>
          <th>Opciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-department>
        <tr>
          <td>{{ department?.companies?.company_name }}</td>
          <td>
            {{ department?.responsible ? department?.responsible : 'No definido'
            }}
          </td>
          <td>{{ department?.main_activity }}</td>
          <td>{{ department?.date | date: 'dd/MM/YY' }}</td>
          <td>
            <button
              pButton
              type="button"
              pTooltip="Editar departamento"
              icon="pi pi-pencil"
              class="m-1"
              (click)="showDepartmentDialog(department)"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              pTooltip="Eliminar departamento"
              class="m-1 p-button-danger"
              (click)="onDeleteClick($event, department.id)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  }
  <!--  -->
  @if(!departments || departments.length === 0){
  <div class="p-col-12">
    <p-message
      severity="warn"
      text="No se encontraron departamentos"
    ></p-message>
  </div>
  }
  <!--  -->
  }
</div>

<!-- Modal para creación/edición de departamentos -->
<p-dialog
  header="Formulario de departamento"
  [(visible)]="dialogDepartment"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="closeDepartmentModal()"
>
  @if(form){
  <div class="form-container">
    <form [formGroup]="form" enctype="multipart/form-data">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>*Empresa</label>
            <div class="mt-1">
              <p-autoComplete
                appendTo="body"
                emptyMessage="No hay resultados"
                formControlName="company"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                [dropdown]="true"
                [suggestions]="filteredCompanies"
                (completeMethod)="filterCompany($event)"
                field="company_name"
              />
            </div>
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="form.get('company')!.errors && form.get('company')!.touched && form.get('company')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="email">Responsable</label>
            <div class="mt-1">
              <p-autoComplete
                appendTo="body"
                emptyMessage="No hay resultados"
                formControlName="responsible"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                [dropdown]="true"
                [suggestions]="filteredUsers"
                (completeMethod)="filterUser($event)"
                field="name"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>*Actividad principal</label>
            <textarea
              rows="5"
              cols="30"
              class="w-full mt-1"
              pInputTextarea
              formControlName="main_activity"
            ></textarea>
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="form.get('main_activity')!.errors && form.get('main_activity')!.touched && form.get('main_activity')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>*Fecha</label>
            <div class="mt-1">
              <p-calendar
                appendTo="body"
                class="calendar-form"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                formControlName="date"
                dateFormat="dd/mm/yy"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>*Funciones de las tareas</label>
            <textarea
              rows="5"
              cols="30"
              class="w-full mt-1"
              pInputTextarea
              formControlName="tasks_functions"
            ></textarea>
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="form.get('tasks_functions')!.errors && form.get('tasks_functions')!.touched && form.get('tasks_functions')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>*Estructura</label>
            <textarea
              rows="5"
              cols="30"
              class="w-full mt-1"
              pInputTextarea
              formControlName="structure"
            ></textarea>
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="form.get('structure')!.errors && form.get('structure')!.touched && form.get('structure')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">*Comentarios</label>
            <textarea
              rows="5"
              cols="30"
              class="w-full mt-1"
              pInputTextarea
              formControlName="comments"
            ></textarea>
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="form.get('comments')!.errors && form.get('comments')!.touched && form.get('comments')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">*Documento</label>
            <div class="mt-1">
              <p-fileUpload
                name="file"
                chooseLabel="Seleccionar archivo"
                [customUpload]="true"
                [showUploadButton]="false"
                (onSelect)="onFileSelect($event)"
                (onRemove)="deleteFileSelected($event)"
                accept=".pdf"
              ></p-fileUpload>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
  }

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>
