import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Section } from 'src/app/models/section';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.page.html',
  styleUrls: ['./sections.page.scss'],
})
export class SectionsPage implements OnInit {
  sections: Section[] = [];
  loading: boolean = true;
  dialogSection: boolean = false;
  sectionSelected: Section;
  edit: boolean = false;
  formSubmitted: boolean = false;
  form: FormGroup;
  loadingForm: boolean = false;
  filteredSections: any[] | undefined;

  constructor(
    private confirmationService: ConfirmationService,
    private apiService: ApiService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      parent: [''],
      parent_id: [''],
    });
    this.getSections();
  }

  /**
   * Obtener listado de secciones
   */
  getSections() {
    this.apiService.getData('/api/sections').subscribe({
      next: (response) => {
        this.sections = response.data ? response.data : [];
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.error('Error al obtener el listado de secciones', error);
      },
    });
  }

  /**
   * Manejador de modal de creación/edición de sección
   * @param section
   */
  showSectionDialog(section?: Section) {
    if (section) {
      this.sectionSelected = section;
      this.form.patchValue(section);
    }

    this.edit = section ? true : false;
    this.dialogSection = true;
  }

  /**
   * Manejador de cierre de modal de sección
   */
  closeSectionModal() {
    this.form.reset();
    this.dialogSection = false;
  }

  /**
   * Filtro de secciones para desplegable
   * @param event
   */
  filterSection(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.sections as any[]).length; i++) {
      let section = (this.sections as any[])[i];
      if (section.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(section);
      }
    }

    this.filteredSections = filtered;
  }

  /**
   * Enviar formulario de creación/edición de sección
   */
  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    // Procesar datos del formulario
    this.form.patchValue({
      parent_id: this.form.get('parent')?.value?.id,
    });

    this.formSubmitted = true;
    this.loadingForm = true;

    // Enviamos datos para creación o edición
    if (this.edit) {
      this.apiService
        .updateDataPut(
          '/api/sections/' + this.sectionSelected.id,
          this.form.value
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Sección actualizada correctamente',
              });
              this.loadingForm = false;
              this.dialogSection = false;
              this.getSections();
            }
          },
          error: (error) => {
            this.loadingForm = false;
            console.error('Error al actualizar la sección', error);
          },
        });
    } else {
      this.apiService.sendData('/api/sections', this.form.value).subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Sección guardada correctamente',
            });
            this.loadingForm = false;
            this.dialogSection = false;
            this.getSections();
          }
        },
        error: (error) => {
          this.loadingForm = false;
          console.error('Error al registrar la sección', error);
        },
      });
    }
  }
}
