import { Injectable } from '@angular/core';
import * as countriesList from 'countries-list';
@Injectable({
  providedIn: 'root',
})
export class Utils {
  /**
   * Renombrado de fichero
   * @param file
   * @param newName
   * @returns
   */
  public static renameFile(file: File, newName: string) {
    const blob = file.slice(0, file.size, file.type);

    const splitName = file.name.split('.');
    const ext = splitName[splitName.length - 1];

    return new File([blob], newName + '.' + ext, {
      type: file.type,
    });
  }

  /**
   * Obtener paises para desplegable
   * @returns
   */
  public static getCountries() {
    const countriesArray: { label: string; value: string }[] = [];
    for (const code in countriesList.countries) {
      if (countriesList.countries.hasOwnProperty(code)) {
        const country = countriesList.countries[code];
        if (country) {
          countriesArray.push({ label: country.name, value: code });
        }
      }
    }
    return countriesArray;
  }

  /**
   * Mostrar errores de formularios
   * @param error
   */
  public static showFormErrorMessage(err) {
    if (err.error.errors.passwordConfirm) {
      return 'Contraseña y confirmación de contraseña deben coincidir';
    }

    return 'Revise los campos del formulario';
  }
}
