<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<div class="p-grid">
  <div class="product-container">
    <h2 class="section-title">Product Details</h2>
    <div class="product-details">
      <div class="product-image-gallery">
        <div class="main-image-container">
          <img [src]="productDetail?.image" alt="Product Image" class="main-image" />
        </div>
      </div>
      <div class="product-info">
        <h2>Apple Bundle</h2>
        <p class="price">$1,419</p>
        <span class="status in-stock">IN STOCK</span>
        <div class="description">
          <h3>Description</h3>
          <ul>
            <li>The most beautiful curves of this swivel stool adds an elegant touch to any environment</li>
            <li>Memory swivel seat returns to original seat position</li>
            <li>Comfortable integrated layered chair seat cushion design</li>
            <li>Fully assembled! No assembly required</li>
          </ul>
        </div>
    </div>
  </div>
</div>
