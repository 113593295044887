<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<div class="p-grid">
  <div class="p-col-12">
    <h2>Empresas</h2>
  </div>
  <div class="p-col-12 mb-4 custom-align-right">
    <button
      pButton
      type="button"
      label="Crear empresa"
      icon="pi pi-plus"
      (click)="showCompanyDialog()"
    ></button>
  </div>

  @if(loading){
    <div class="spinner-container">
      <ion-spinner name="dots"> </ion-spinner>
    </div>
  }

  @if(!loading && companies.length > 0){
    <div class="table-container p-col-12">
      <p-table [value]="companies" [paginator]="true" [rows]="10">
        <ng-template pTemplate="header">
          <tr>
            <th>Razón social</th>
            <th>Código Postal</th>
            <th>Dirección Fiscal</th>
            <th>Email</th>
            <th>Opciones</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
          <tr>
            <td>{{ company?.company_name }}</td>
            <td>
              {{ company?.fiscal_postal_code ? company?.fiscal_postal_code : '-'
              }}
            </td>
            <td>{{ company?.fiscal_address ? company?.fiscal_address : '-' }}</td>
            <td>{{ company?.email ? company.email : '-' }}</td>
            <td>
              <p-dropdown                 
                [options]="crudOptionsCompanies"
                appendTo="body"
                class="w-full"
                placeholder="Seleccione una opción"
                optionLabel="label"
                optionValue="value"
                [(ngModel)]="selectedCrudOption"
                (onChange)="handleDropdown($event, company)"
              >
              </p-dropdown>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  }

  @if(!loading && (!companies || companies.length === 0)){
    <div class="p-col-12">
      <p-message severity="warn" text="No se encontraron empresas"></p-message>
    </div>
  }
</div>

<!-- Modal para creación/edición de empresa -->
<p-dialog
  header="Formulario de empresa"
  [(visible)]="dialogCompany"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="closeCompanyClose()"
>
  <div class="form-container">
    <form [formGroup]="companyForm" enctype="multipart/form-data">
      <div class="container-img">
        <div class="custom-file-upload" *ngIf="mainStringPhoto">
          <img [src]="mainStringPhoto" style="width: 15vw" alt="Foto cargada" />
          <button
            class="ml-2"
            type="button"
            pButton
            icon="pi pi-trash"
            (click)="deletePhoto()"
          ></button>
        </div>
        <div class="custom-file-upload">
          <input
            id="photoInput2"
            type="file"
            (change)="handleUploadPhoto($event)"
            accept=".jpg,.png"
          />
          <label class="btn-img">Selecciona imagen de empresa</label>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>CIF</label>
            <input
              pInputText
              formControlName="cif"
              maxlength="9"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="email">*Razón social</label>
            <input
              pInputText
              formControlName="company_name"
              class="mt-1 w-full"
            />
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="companyForm.get('company_name')!.errors && companyForm.get('company_name')!.touched && companyForm.get('company_name')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="email">Dirección</label>
            <input
              pInputText
              formControlName="fiscal_address"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Email</label>
            <input
              pInputText
              formControlName="email"
              type="email"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Cifra de ventas el último ejercicio</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              formControlName="number_millions"
              mode="decimal"
              [allowEmpty]="true"
              [maxFractionDigits]="2"
              [min]="0"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Cifra de negocio</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              mode="decimal"
              [allowEmpty]="true"
              formControlName="business_figure"
              [min]="0"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Empresas intersectoriales</label>
            <div class="mt-1">
              <p-dropdown
                [options]="yesOrNo"
                class="w-full"
                placeholder="Seleccione una opción"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                formControlName="cross_sector_companies"
                optionLabel="label"
                optionValue="value"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Impuesto de sociedades</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              inputId="percent"
              suffix="%"
              [maxFractionDigits]="1"
              [max]="100"
              [min]="0"
              formControlName="corporate_tax"
            />
          </ion-col>
          <!-- <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Responsable</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              mode="decimal"
              inputId="withoutgrouping"
              [useGrouping]="false"
              formControlName="officer_id"
            />
          </ion-col> -->
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Nivel de ponderación</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              mode="decimal"
              [min]="0"
              formControlName="level_weighting"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Código postal</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              inputId="withoutgrouping"
              [useGrouping]="false"
              formControlName="fiscal_postal_code"
              maxlength="5"
              minlength="5"
              [max]="99999"
              [min]="00000"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Número de empleados</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              mode="decimal"
              [min]="0"
              formControlName="employees_num"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Capital social</label>
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              mode="decimal"
              [allowEmpty]="true"
              [min]="0"
              formControlName="social_capital"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Domicilio fiscal</label>
            <input
              pInputText
              formControlName="fiscal_address"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Provincia fiscal</label>
            <input
              pInputText
              formControlName="fiscal_province"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Sector</label>
            <input pInputText formControlName="sector" class="mt-1 w-full" />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>CNAE</label>
            <input pInputText formControlName="cnae" class="mt-1 w-full" />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Teléfono</label>
            <input
              pInputText
              formControlName="phone"
              type="tel"
              maxlength="9"
              class="mt-1 w-full"
            />
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Reevaluación</label>
            <div class="mt-1">
              <p-dropdown
                [options]="yesOrNo"
                class="w-full"
                placeholder="Seleccione una opción"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                formControlName="reevaluation"
                optionLabel="label"
                optionValue="value"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Fecha de inicio de actividad</label>
            <div class="mt-1">
              <p-calendar
                appendTo="body"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                class="calendar-form"
                formControlName="start_activity"
                dateFormat="dd/mm/yy"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="pais">Selecciona país de la organización</label>
            <div class="mt-1">
              <p-dropdown
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                appendTo="body"
                placeholder="Selecciona país"
                formControlName="fiscal_country"
                [options]="countries"
                optionLabel="label"
                optionValue="value"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Documento</label>
            <div class="mt-1">
              <p-fileUpload
                name="file"
                chooseLabel="Seleccionar archivo"
                [customUpload]="true"
                [showUploadButton]="false"
                (onSelect)="onFileSelect($event)"
                (onRemove)="deleteFileSelected($event)"
                accept=".pdf"
              ></p-fileUpload>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal para gestionar los departamentos de una empresa -->
<p-dialog
  header="Departamentos"
  [(visible)]="dialogDepartments"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="closeDepartmentModal()"
>
  <!-- Selector -->
  <div class="options-container">
    <p-selectButton
      [options]="departmentOptions"
      [(ngModel)]="departmentOptionSelected"
      optionLabel="label"
      optionValue="value"
    />
  </div>

  <!-- Nuevo departamento -->
  @if(departmentOptionSelected === 'NEW'){
  <!--  -->
  <form [formGroup]="departmentForm" enctype="multipart/form-data">
    <ion-grid>
      <ion-row>
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="email">Responsable</label>
              <div class="mt-1">
                <p-autoComplete
                  appendTo="body"
                  emptyMessage="No hay resultados"
                  formControlName="responsible"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  [dropdown]="true"
                  [suggestions]="filteredEmployees"
                  (completeMethod)="filterEmployee($event)"
                  field="name"
                />
              </div>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>*Actividad principal</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="main_activity"
              ></textarea>
              <span
                class="flex justify-content-center text-center m-2"
                style="color: red; font-size: 12px"
                *ngIf="departmentForm.get('main_activity')!.errors && departmentForm.get('main_activity')!.touched && departmentForm.get('main_activity')!.hasError('required')"
                >Requerido</span
              >
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>Fecha</label>
              <div class="mt-1">
                <p-calendar
                  appendTo="body"
                  class="calendar-form"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  formControlName="date"
                  dateFormat="dd/mm/yy"
                />
              </div>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>Funciones de las tareas</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="tasks_functions"
              ></textarea>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>Estructura</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="structure"
              ></textarea>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="name col-6">Comentarios</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="comments"
              ></textarea>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="name col-6">Documento</label>
              <div class="mt-1">
                <p-fileUpload
                  name="file"
                  chooseLabel="Seleccionar archivo"
                  [customUpload]="true"
                  [showUploadButton]="false"
                  (onSelect)="onFileSelect($event)"
                  (onRemove)="deleteFileSelected($event)"
                  accept=".pdf"
                ></p-fileUpload>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-row>
    </ion-grid>
  </form>
  }
  <!-- Departamentos existentes -->
  @if(departmentOptionSelected === 'LIST'){
  <!--  -->
  @if(loadingDepartments){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }
  <!--  -->
  <div class="list-container">
    @if(!loadingDepartments && departments.length > 0){
    <p-table
      [globalFilterFields]="[]"
      styleClass="p-datatable-striped"
      responsiveLayout="scroll"
      [value]="departments"
      [paginator]="true"
      [rowHover]="true"
      [rows]="5"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Actividad principal</th>
          <th>Responsable</th>
          <th>Fecha</th>
          <th>Opciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-department>
        <tr>
          <td>{{ department?.main_activity }}</td>
          <td>
            {{ department?.responsible ?
            department?.responsible_department?.user?.name : '-' }}
          </td>
          <td>
            {{ department?.date ? (department?.date | date: 'dd/MM/YY') : '-' }}
          </td>
          <td>
            <button
              pButton
              pTooltip="Editar departamento"
              type="button"
              icon="pi pi-pencil"
              class="m-1"
              (click)="showDepartmentEditDialog(department)"
            ></button>
            <button
              pButton
              type="button"
              pTooltip="Eliminar empresa"
              icon="pi pi-trash"
              class="m-1 p-button-danger"
              (click)="onDeleteDepartmentClick($event, department.id)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    }
    <!--  -->
    @if(!loadingDepartments && departments.length === 0) {
    <div class="empty-container">
      <span>No hay departamentos registrados para esta empresa</span>
    </div>
    }
  </div>
  }
  <!-- Footer  -->
  <ng-template pTemplate="footer">
    @if(departmentOptionSelected === 'NEW'){
    <ng-container>
      <p-button
        label="Guardar"
        [loading]="loadingForm"
        (click)="onSubmitDepartment()"
      ></p-button>
    </ng-container>
    }
  </ng-template>
</p-dialog>

<!-- Modal de edición de departamento -->
<p-dialog
  header="Editar departamento"
  [(visible)]="dialogDepartmentEdit"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="closeDepartmentEditModal()"
>
  <form [formGroup]="departmentForm" enctype="multipart/form-data">
    <ion-grid>
      <ion-row>
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="email">Responsable</label>
              <div class="mt-1">
                <p-autoComplete
                  appendTo="body"
                  emptyMessage="No hay resultados"
                  formControlName="responsible"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  [dropdown]="true"
                  [suggestions]="filteredEmployees"
                  (completeMethod)="filterEmployee($event)"
                  field="name"
                />
              </div>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>*Actividad principal</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="main_activity"
              ></textarea>
              <span
                class="flex justify-content-center text-center m-2"
                style="color: red; font-size: 12px"
                *ngIf="departmentForm.get('main_activity')!.errors && departmentForm.get('main_activity')!.touched && departmentForm.get('main_activity')!.hasError('required')"
                >Requerido</span
              >
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>Fecha</label>
              <div class="mt-1">
                <p-calendar
                  appendTo="body"
                  class="calendar-form"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  formControlName="date"
                  dateFormat="dd/mm/yy"
                />
              </div>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>Funciones de las tareas</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="tasks_functions"
              ></textarea>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label>Estructura</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="structure"
              ></textarea>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="name col-6">Comentarios</label>
              <textarea
                rows="5"
                cols="30"
                class="w-full mt-1"
                pInputTextarea
                formControlName="comments"
              ></textarea>
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="name col-6">Documento</label>
              <div class="mt-1">
                <p-fileUpload
                  name="file"
                  chooseLabel="Seleccionar archivo"
                  [customUpload]="true"
                  [showUploadButton]="false"
                  (onSelect)="onFileSelect($event)"
                  (onRemove)="deleteFileSelected($event)"
                  accept=".pdf"
                ></p-fileUpload>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-row>
    </ion-grid>
  </form>

  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingForm"
      (click)="onSubmitDepartment(true)"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal para asignar usuarios a empresas (roles, permisos, etc) -->
<p-dialog
  header="Asignación de usuarios"
  [(visible)]="dialogUserAssign"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
  (onHide)="closeUserAssignModal()"
>
  <!-- Paso 1: seleccionar rol -->
  @if(step1){
  <!-- Selector -->
  <label class="flex justify-content-center p-2">
    Seleccione el tipo de usuario que desea asignar
  </label>
  <div class="options-container">
    <p-selectButton
      [options]="userTypes"
      [(ngModel)]="userTypeSelected"
      optionLabel="label"
      optionValue="value"
    />
  </div>
  }
  <!-- Paso 2: seleccionar usuarios para asignar el rol seleccionado en paso 1S -->
  @if(step2){
  <!--  -->
  @if(loadingStep2){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }
  <!--  -->
  @if(!loadingStep2 && userTypeSelected === 'EMPLOYEE'){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <form [formGroup]="employeesForm" (ngSubmit)="onSubmit()">
          <ion-grid formArrayName="records">
            <ion-row
              *ngFor="let record of employeesRecords.controls; let i = index"
              [formGroupName]="i"
            >
              <ion-col size-xs="12" size-sm="12" size-md="5">
                <!--  -->
                <p-dropdown
                  formControlName="user_id"
                  [options]="usersWithoutRole"
                  appendTo="body"
                  optionLabel="name"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  optionValue="id"
                  placeholder="Selecciona un usuario"
                >
                </p-dropdown>

                <!--  -->
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="5">
                <p-dropdown
                  formControlName="department_id"
                  appendTo="body"
                  [options]="departments"
                  optionLabel="main_activity"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  optionValue="id"
                  placeholder="Selecciona un departmento"
                >
                </p-dropdown>
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="2">
                <p-button
                  [disabled]="employeesRecords.controls.length === 1"
                  class="w-full"
                  severity="danger"
                  icon="pi pi-times"
                  label="Eliminar"
                  (click)="removeRecord(i)"
                ></p-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <p-button
                  label="Añadir"
                  icon="pi pi-plus"
                  severity="success"
                  (click)="addRecord()"
                ></p-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <!--  -->
  @if(!loadingStep2 && userTypeSelected === 'CUSTOMER'){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <form [formGroup]="customersForm" (ngSubmit)="onSubmit()">
          <ion-grid formArrayName="records">
            <ion-row
              *ngFor="let record of customersRecords.controls; let i = index"
              [formGroupName]="i"
            >
              <ion-col size-xs="12" size-sm="12" size-md="10">
                <!--  -->
                <p-dropdown
                  formControlName="user_id"
                  [options]="usersWithoutRole"
                  appendTo="body"
                  optionLabel="name"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  optionValue="id"
                  placeholder="Selecciona un usuario"
                >
                </p-dropdown>

                <!--  -->
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="2">
                <p-button
                  [disabled]="customersRecords.controls.length === 1"
                  class="w-full"
                  severity="danger"
                  label="Eliminar"
                  icon="pi pi-times"
                  (click)="removeRecord(i)"
                ></p-button>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <p-button
                  label="Añadir"
                  icon="pi pi-plus"
                  severity="success"
                  (click)="addRecord()"
                ></p-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <!--  -->
  @if(!loadingStep2 && userTypeSelected === 'SUPPLIER'){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <form [formGroup]="suppliersForm" (ngSubmit)="onSubmit()">
          <ion-grid formArrayName="records">
            <ion-row
              *ngFor="let record of suppliersRecords.controls; let i = index"
              [formGroupName]="i"
            >
              <ion-col size-xs="12" size-sm="12" size-md="10">
                <!--  -->
                <p-dropdown
                  formControlName="user_id"
                  [options]="usersWithoutRole"
                  appendTo="body"
                  optionLabel="name"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  optionValue="id"
                  placeholder="Selecciona un usuario"
                >
                </p-dropdown>

                <!--  -->
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="2">
                <p-button
                  [disabled]="suppliersRecords.controls.length === 1"
                  class="w-full"
                  severity="danger"
                  icon="pi pi-times"
                  label="Eliminar"
                  (click)="removeRecord(i)"
                ></p-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <p-button
                  label="Añadir"
                  icon="pi pi-plus"
                  severity="success"
                  (click)="addRecord()"
                ></p-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <!--  -->
  @if(!loadingStep2 && userTypeSelected === 'NONE'){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <form [formGroup]="noneForm" (ngSubmit)="onSubmit()">
          <ion-grid formArrayName="records">
            <ion-row
              *ngFor="let record of noneRecords.controls; let i = index"
              [formGroupName]="i"
            >
              <ion-col size-xs="12" size-sm="12" size-md="10">
                <!--  -->
                <p-dropdown
                  formControlName="user_id"
                  [options]="usersWithoutRole"
                  appendTo="body"
                  optionLabel="name"
                  [style]="{'width':'100%'}"
                  [inputStyle]="{'width':'100%'}"
                  optionValue="id"
                  placeholder="Selecciona un usuario"
                >
                </p-dropdown>
                <!--  -->
              </ion-col>
              <ion-col size-xs="12" size-sm="12" size-md="2">
                <p-button
                  [disabled]="noneRecords.controls.length === 1"
                  class="w-full"
                  severity="danger"
                  icon="pi pi-times"
                  label="Eliminar"
                  (click)="removeRecord(i)"
                ></p-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <p-button
                  label="Añadir"
                  icon="pi pi-plus"
                  severity="success"
                  (click)="addRecord()"
                ></p-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <!--  -->
  }
  <!-- Footers  -->
  <ng-template pTemplate="footer">
    @if(step1){
    <ng-container>
      <p-button label="Siguiente" (click)="nextStepAssignUser()"></p-button>
    </ng-container>
    }
    <!--  -->
    @if(step2){
    <ng-container>
      <p-button
        label="Guardar"
        (click)="onSubmitCompanyUsers()"
        [loading]="loadingForm"
      ></p-button>
    </ng-container>
    }
  </ng-template>
</p-dialog>

<!-- Modal para visualizar usuarios de una empresa -->
<p-dialog
  header="{{(step2 && userTypeSelected === 'CUSTOMER' ? 'Clientes ' : step2 && userTypeSelected === 'SUPPLIER' ? 'Proveedores ' : step2 && userTypeSelected === 'EMPLOYEE' ? 'Empleados ' : !step2 && userTypeSelected === 'NONE' ? 'Usuarios ' : 'Usuarios ') + 'de la empresa ' + companySelected?.company_name}}"
  [(visible)]="dialogCompanyUsers"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
  (onHide)="closeViewCompanyUsers()"
>
  <!-- Paso 1: seleccionar rol -->
  @if(step1){
  <!-- Selector -->
  <label class="flex justify-content-center p-2">
    Seleccione el tipo de usuario que desea visualizar
  </label>
  <div class="options-container">
    <p-selectButton
      [options]="userTypes"
      [(ngModel)]="userTypeSelected"
      optionLabel="label"
      optionValue="value"
    />
  </div>
  }
  <!--  -->
  @if(step2){
  <!--  -->
  @if(loadingStep2){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }
  <!--  -->
  @if(!loadingStep2){
  <div class="table-container p-col-12">
    <p-table
      [globalFilterFields]="[]"
      styleClass="p-datatable-striped"
      responsiveLayout="scroll"
      [value]="companyUsersByRole"
      [paginator]="true"
      [rowHover]="true"
      [rows]="5"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
          <th>Opciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            {{ userTypeSelected !== 'EMPLOYEE' && userTypeSelected !== 'NONE' ?
            user?.user?.name : user?.name }}
          </td>
          <td>
            @if(userTypeSelected !== 'NONE'){
            <button
              pButton
              pTooltip="{{'Editar ' + (userTypeSelected === 'EMPLOYEE' ? 'empleado' : userTypeSelected === 'NONE' ? 'asociado' : userTypeSelected === 'SUPPLIER' ? 'proveedor' : userTypeSelected === 'CUSTOMER' ? 'cliente' : 'usuario')}}"
              type="button"
              icon="pi pi-pencil"
              class="m-1"
              (click)="showSubModalUpdate(user)"
            ></button>
            }
            <button
              pButton
              type="button"
              pTooltip="{{'Eliminar ' + (userTypeSelected === 'EMPLOYEE' ? 'empleado' : userTypeSelected === 'NONE' ? 'asociado' : userTypeSelected === 'SUPPLIER' ? 'proveedor' : userTypeSelected === 'CUSTOMER' ? 'cliente' : 'usuario')}}"
              icon="pi pi-trash"
              class="m-1 p-button-danger"
              (click)="deleteUserByRol($event, user)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No se encontraron resultados</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- Formulario auxiliar segun usuario y tipo de usuario seleccionados -->
  @if(selectedUser && userTypeSelected === 'EMPLOYEE'){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <label for="">Departamentos pertenecientes al usuario</label>
        <div class="mt-1">
          <p-dropdown
            appendTo="body"
            [(ngModel)]="employeeDepartmentSelected"
            [options]="selectedUser['employees']"
            optionLabel="department.main_activity"
            [style]="{'width':'100%'}"
            [inputStyle]="{'width':'100%'}"
            placeholder="Selecciona un registro"
            (ngModelChange)="handleEmployeeDepartmentData($event)"
          >
          </p-dropdown>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  @if(auxiliarFormDefined){
  <form [formGroup]="auxiliarForm">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="name col-6">*Departamento</label>
          <div class="mt-1">
            <p-dropdown
              formControlName="department_id"
              appendTo="body"
              [options]="departments"
              optionLabel="main_activity"
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              optionValue="id"
              placeholder="Selecciona un departmento"
            >
            </p-dropdown>
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="name col-6">Responsable</label>
          <div class="mt-1">
            <p-dropdown
              formControlName="responsible"
              appendTo="body"
              [options]="users"
              optionLabel="name"
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              optionValue="id"
              placeholder="Selecciona un responsable"
            >
            </p-dropdown>
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Fecha de entrada</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="calendar-form"
              formControlName="entry_date"
              dateFormat="dd/mm/yy"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Documento</label>
          <div class="mt-1">
            <p-fileUpload
              name="file"
              chooseLabel="Seleccionar archivo"
              [customUpload]="true"
              [showUploadButton]="false"
              (onSelect)="onFileSelect($event)"
              (onRemove)="deleteFileSelected($event)"
              accept=".pdf"
            ></p-fileUpload>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  }
  <!--  -->
  }

  <!--  -->
  @if(selectedUser && (userTypeSelected === 'CUSTOMER' || userTypeSelected ===
  'SUPPLIER')){
  <!--  -->
  @if(auxiliarFormDefined){
  <form [formGroup]="auxiliarForm">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="name col-6">Responsable</label>
          <div class="mt-1">
            <p-dropdown
              formControlName="responsible"
              appendTo="body"
              [options]="users"
              optionLabel="name"
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              optionValue="id"
              placeholder="Selecciona un responsable"
            >
            </p-dropdown>
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Fecha de entrada</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="calendar-form"
              formControlName="entry_date"
              dateFormat="dd/mm/yy"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Dirección</label>
          <input pInputText formControlName="address" class="mt-1 w-full" />
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Código postal</label>
          <div class="mt-1">
            <input
              pInputText
              maxlengt="5"
              formControlName="zip_code"
              class="mt-1 w-full"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Ciudad</label>
          <div class="mt-1">
            <input pInputText formControlName="city" class="mt-1 w-full" />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Provincia</label>
          <div class="mt-1">
            <input pInputText formControlName="province" class="mt-1 w-full" />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>País</label>
          <div class="mt-1">
            <input pInputText formControlName="country" class="mt-1 w-full" />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Criticalidad</label>
          <div class="mt-1">
            <p-inputNumber
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              class="mt-1 w-full"
              inputId="integeronly"
              formControlName="criticality"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Documento</label>
          <div class="mt-1">
            <p-fileUpload
              name="file"
              chooseLabel="Seleccionar archivo"
              [customUpload]="true"
              [showUploadButton]="false"
              (onSelect)="onFileSelect($event)"
              (onRemove)="deleteFileSelected($event)"
              accept=".pdf"
            ></p-fileUpload>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  }
  <!--  -->
  }
  <!--  -->
  }
  <!--  -->
  }
  <ng-template pTemplate="footer">
    @if(step1){
    <ng-container>
      <p-button
        label="Siguiente"
        (click)="nextStepViewCompanyUsers()"
      ></p-button>
    </ng-container>
    }
    <!--  -->
    @if(step2 && selectedUser && userTypeSelected !== 'EMPLOYEE' || step2 &&
    selectedUser && userTypeSelected === 'EMPLOYEE' &&
    employeeDepartmentSelected){
    <ng-container>
      <p-button
        label="Guardar"
        (click)="updateCompanyUsers()"
        [loading]="loadingForm"
      ></p-button>
    </ng-container>
    }
  </ng-template>
</p-dialog>

<!-- Modal para gestión de usuarios de empresa -->
<p-dialog
  header="Gestión de usuarios"
  [(visible)]="dialogUserManagement"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="selectedCrudOption = null; companySelected = null"
>
  <div class="p-4 align-items-center justify-content-center flex w-full gap-3">
    <p-button
      label="Añadir usuario a la empresa"
      (click)="handleUsersToCompany()"
    ></p-button>
    <p-button
      label="Ver usuarios de la empresa"
      (click)="viewCompanyUsers()"
    ></p-button>
  </div>
</p-dialog>
