<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<div class="p-grid">
  <div class="p-col-12">
    <h2>Secciones</h2>
  </div>
  <div class="p-col-12 mb-4 custom-align-right">
    <button
      pButton
      type="button"
      label="Crear sección"
      icon="pi pi-plus"
      (click)="showSectionDialog()"
    ></button>
  </div>
  @if(loading){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }
  <!--  -->
  @if(sections && sections.length > 0){
  <div class="p-col-12">
    <p-table [value]="sections" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
          <th>Opciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-section>
        <tr>
          <td>{{ section?.name }}</td>
          <td>
            <button
              pButton
              type="button"
              pTooltip="Editar sección"
              icon="pi pi-pencil"
              class="m-1"
              (click)="showSectionDialog(section)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  }
  <!--  -->
  @if(!loading && (!sections || sections.length === 0)){
  <div class="p-col-12">
    <p-message severity="warn" text="No se encontraron secciones"></p-message>
  </div>
  }
</div>

<!-- Modal para creación/edición de sección -->
<p-dialog
  header="Formulario de sección"
  [(visible)]="dialogSection"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="closeSectionModal()"
>
  @if(form){
  <div class="form-container">
    <form [formGroup]="form" enctype="multipart/form-data">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Sección principal (opcional)</label>
            <div class="mt-1">
              <p-autoComplete
                appendTo="body"
                emptyMessage="No hay resultados"
                formControlName="parent"
                [style]="{'width':'100%'}"
                [inputStyle]="{'width':'100%'}"
                [dropdown]="true"
                [suggestions]="filteredSections"
                (completeMethod)="filterSection($event)"
                field="name"
              />
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label>Nombre</label>
            <input
              type="text"
              id="name"
              pInputText
              formControlName="name"
              class="w-full mt-1"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>
  }

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>
