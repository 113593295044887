import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    menu: MenuItem[] = [];

    user: any;

    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton') menuButton!: ElementRef;

    searchActive: boolean = false;

    constructor(public layoutService: LayoutService, private http: HttpClient, private router: Router, private userService: UserService) { }

    ngOnInit() {
        this.getUserInfo();
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config().layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    get logo(): string {
        const path = 'assets/layout/images/logo-';
        const logo = (this.layoutTheme === 'primaryColor' && !(this.layoutService.config().theme == "yellow")) ? 'light.png' : (this.colorScheme === 'light' ? 'dark.png' : 'light.png');
        return path + logo;
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }

    logout() {
        this.postLogout()
            .pipe(
                map((data) => {
                    this.router.navigate(['/login']);
                    return data;
                }),
                catchError((error) => {
                    console.error('Error!', error);
                    return throwError(error);
                })
            )
            .subscribe();
    }

    postLogout(): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + this.userService.getToken(),
        });
        return this.http.post(`${UserService.apiUrl}/api/logout`, null, { headers: headers });
    }

    getUserInfo() {
        this.userService.getActualUser().subscribe(
            user => {
                this.user = user;
            },
        );
    }
}
