import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { ApiService } from '../services/api.service';
import { User } from '../models/user';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: MenuItem[] = [];
  user: User;

  constructor(
    public layoutService: LayoutService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.getCurrentUser();
  }

  /**
   * Obtener usuario actual
   */
  getCurrentUser() {
    this.apiService.getData('/api/user').subscribe({
      next: (response) => {
        this.user = response;
        this.initializeMenu(); // Inicializa el menú después de obtener el usuario
      },
      error: (err) => {
        console.error('Error al obtener usuario actual', err);
        this.initializeMenu(); // Inicializa el menú incluso en caso de error
      },
    });
  }

  /**
   * Inicializa el menú basado en la información del usuario
   */
  initializeMenu() {
    this.model = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-home',
            routerLink: ['/dashboard'],
          },
        ],
      },
    ];

    if (this.user && this.user.id === 1) {
      this.model.push({
        label: 'User management',
        icon: 'pi pi-user',
        items: [
          { label: 'Usuarios', icon: 'pi pi-user', routerLink: ['/users'] },
          {
            label: 'Empresas',
            icon: 'pi pi-chart-bar',
            routerLink: ['/companies'],
          },
          {
            label: 'Secciones',
            icon: 'pi pi-sitemap',
            routerLink: ['/sections'],
          },
        ],
      });
    }
  }
}
