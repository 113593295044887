import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // constructor(private router: Router, private location: Location) {}

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       if (error.status === 401) {
  //         // Obtiene la URL actual
  //         const currentUrl = this.location.path();
  //         // Lógica según la URL actual
  //         if (currentUrl.includes('localhost:8204')) {
  //           this.router.navigate(['/login']);
  //         } else if (currentUrl.includes('localhost:8100')) {
  //           this.router.navigate(['localhost:8204/login']);
  //         }
  //       }
  //       return throwError(error);
  //     })
  //   );
  // }
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.authService.getToken();
    let request = req;
    if (req.url.includes(environment.usersApiURL) && token) {
      if (
        this.authService.isTimeToRefresh() &&
        !req.url.includes(`${environment.usersApiURL}/api/refreshToken`)
      )
        this.authService.refresh().subscribe();
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.router.navigateByUrl('/login');
          sessionStorage.clear();
        }

        return throwError(err);
      })
    );
  }
}
