import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { Table } from 'primeng/table';
import { crudOptionsProducts } from 'src/app/app.const';
import { Utils } from 'src/app/app.utils';
import { Company } from 'src/app/models/company';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-company-products',
  templateUrl: './company-products.page.html',
  styleUrls: ['./company-products.page.scss'],
})
export class CompanyProductsPage implements OnInit {
  @ViewChild('dt') table: Table;
  edit: boolean = false;
  dialogProduct: boolean = false;
  products: Product[] = [];
  loading: boolean = true;
  crudOptionsProducts = crudOptionsProducts;
  selectedCrudOption: number = 0;
  loadingForm: boolean = false;
  uploadedFile: File | null = null;
  mainPhoto: File | undefined;
  mainStringPhoto: any;
  productForm: FormGroup;
  productSelected: Product | null;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.products = [
      {
        id: 1,
        name: 'Iphone 16',
        quantity: 9000,
        sku: '50531',
        image: 'assets/images/image3.jpg'
      },
      {
        id: 2,
        name: 'MacBook 1',
        quantity: 9000,
        sku: '55531',
        image: 'assets/images/image1.jpg'
      },
      {
        id: 3,
        name: 'Bicicleta',
        quantity: 6000,
        sku: '10561',
        image: 'assets/images/image2.jpg'
      },
      {
        id: 4,
        name: 'MacBook 2',
        quantity: 500,
        sku: '60576',
        image: 'assets/images/image1.jpg'
      },
      {
        id: 5,
        name: 'Bicicleta 2',
        quantity: 700,
        sku: '55731',
        image: 'assets/images/image2.jpg'
      },
    ];

    // Inicializar formulario
    this.formInit();
    this.loading = false;
  }

  formInit(){
    this.productForm = this.fb.group({
      name: [''],
      image: [''],
      sku: [''],
      quantity: [''],
    })
  }

  /**
   * Mostrar modal de creación/edición de producto
   * @param company 
   */
  showProductDialog(product?: Product) {
    if (product) {
      this.productForm.patchValue(product);
      this.productSelected = product;
      this.mainStringPhoto = product.image;
    }

    this.edit = product ? true : false;
    this.dialogProduct = true;
  }


  /**
   * Manejar opción seleccionada en opciones de un producto
   */
  handleDropdown(event: any, product: Product){
    const action = event.value;

    if (action === 1) {
      this.router.navigateByUrl('/product-detail', {
        state: { product }
      });
    }

    if(action === 2){
      this.router.navigateByUrl('/lifecycle', {
        state: { 
          product: product, 
        }
      });
    }

    if(action === 3){
      this.showProductDialog(product);
    }

    if(action === 4){
      this.onDeleteClick(product);
    }

    // Pequeño retardo para aplicar al desplegable
    setTimeout(() => {
      this.selectedCrudOption = 0;
    })
  }

  /**
   * Manejar eliminación de producto
   * @param prouct 
   */
  onDeleteClick(product: Product) {
    this.confirmationService.confirm({
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este producto?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  /**
   * Enviar formulario de producto
   */
  onSubmit(){}

  /**
   * Manejar cierre de modal de creación/edición de producto
   */
  closeProductClose(){
    this.productForm.reset();
    this.deletePhoto();
  }

  /**
   * Eliminar imagen adjunta al formulario
   */
  deletePhoto(){
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }

  /**
   * Manejar imagen adjunta al formulario
   */
  handleUploadPhoto($event: any) {
    try {
      const files: FileList = $event.target.files;
      this.mainPhoto = Utils.renameFile(
        files[0],
        new Date().getTime().toString()
      );
      var reader = new FileReader();
      if (
        this.mainPhoto.type === 'image/jpeg' ||
        this.mainPhoto.type === 'image/png'
      ) {
        reader.onloadend = async () => {
          $event.target.value = '';
          this.mainStringPhoto = String(reader.result);
        };
        reader.readAsDataURL(this.mainPhoto);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  }
}
