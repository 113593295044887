<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<div class="p-grid">
  <div class="p-col-12">
    <h2>Usuarios</h2>
  </div>
  <div class="p-col-12 mb-4 custom-align-right">
    <button
      pButton
      type="button"
      label="Crear usuario"
      icon="pi pi-plus"
      (click)="showUserDialog()"
    ></button>
  </div>
  @if(loading){
  <div class="spinner-container">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }
  <!--  -->
  @if(!loading && users.length > 0) {
  <div class="p-col-12">
    <p-table [value]="users" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Opciones</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <button
              pButton
              type="button"
              class="m-1"
              pTooltip="Editar usuario"
              icon="pi pi-pencil"
              (click)="showUserDialog(user)"
            ></button>
            <button
              pButton
              type="button"
              pTooltip="Vincular usuario a una empresa"
              icon="pi pi-users"
              class="m-1 p-button-success"
              (click)="addUserToCompany(user)"
              [loading]="loadingAddUserToCompany"
            ></button>
            <button
              pButton
              type="button"
              pTooltip="Gestionar permisos de acceso a secciones de un usuario"
              icon="pi pi-list"
              class="m-1 p-button-warning"
              (click)="handleClickPermissionByUser($event, user)"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              pTooltip="Eliminar usuario"
              class="m-1 p-button-danger"
              (click)="onDeleteClick($event, user.id)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  @if(!loading && (!users || users.length === 0)){
  <div class="p-col-12">
    <p-message severity="warn" text="No se encontraron usuarios"></p-message>
  </div>
  } }
</div>

<!-- Modal de creación/edición de usuarios -->
<p-dialog
  header="Formulario de usuario"
  [(visible)]="dialogUser"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="closeUserModal()"
>
  <div class="form-container">
    <form [formGroup]="formUser" enctype="multipart/form-data">
      <div class="container-img">
        <div class="custom-file-upload" *ngIf="mainStringPhoto">
          <img [src]="mainStringPhoto" style="width: 15vw" alt="Foto cargada" />
          <button
            class="ml-2"
            type="button"
            pButton
            icon="pi pi-trash"
            (click)="deletePhoto()"
          ></button>
        </div>
        <div class="custom-file-upload">
          <input
            id="photoInput2"
            type="file"
            (change)="handleUploadPhoto($event)"
            accept=".jpg,.png"
          />
          <label class="btn-img">Selecciona imagen de perfil</label>
        </div>
      </div>

      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">*Nombre completo</label>
            <input
              type="text"
              id="name"
              pInputText
              formControlName="name"
              class="w-full mt-1"
            />
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="formUser.get('name')!.errors && formUser.get('name')!.touched && formUser.get('name')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="email">*Email</label>
            <input
              pInputText
              id="email"
              formControlName="email"
              class="w-full mt-1"
            />
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="formUser.get('email')!.errors && formUser.get('email')!.touched && formUser.get('email')!.hasError('required')"
              >Requerido</span
            >
          </ion-col>

          <!-- Editar contraseña -->
          @if(edit){
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Contraseña</label>
            <div class="mt-1">
              <p-password
                appendTo="body"
                formControlName="password"
                [feedback]="true"
                [toggleMask]="true"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }"
              >
              </p-password>
              <span
                class="flex justify-content-center text-center m-2"
                style="color: red; font-size: 12px"
                *ngIf="formUser.get('password')!.errors && formUser.get('password')!.touched && formUser.get('password')!.errors?.['minlength']"
                >Mínimo 8 caracteres</span
              >
            </div>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="name col-6">Confirmación de contraseña</label>
            <div class="mt-1">
              <p-password
                appendTo="body"
                formControlName="repeat_password"
                [feedback]="false"
                [toggleMask]="true"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }"
              >
              </p-password>
            </div>
          </ion-col>
          }
          <!--  -->
          @if (boolAzure && !edit) {
          <div
            class="w-full h-full flex align-items-center justify-content-center p-4"
          >
            <span
              >Se usará contraseña de cuenta de Microsoft asociada al email que
              se defina en este formulario</span
            >
          </div>
          }
        </ion-row>
      </ion-grid>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      label="Guardar usuario"
      [loading]="loadingForm"
      (click)="onSubmitUser()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal para asignar usuario seleccionado a una empresas -->
<p-dialog
  header="{{'Vincular a una empresa a: ' + selectedUser?.name}}"
  [(visible)]="modalAddUserToCompany"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '50vw' }"
  (onHide)="closeSectionsModal()"
>
  <ion-grid>
    <ion-row>
      <!-- Seleccionar company -->
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-dropdown
          [(ngModel)]="selectedCompany"
          [options]="companies"
          appendTo="body"
          optionLabel="company_name"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          optionValue="id"
          placeholder="Selecciona una empresa"
          (onChange)="handleSelectedCompany($event)"
        >
        </p-dropdown>
      </ion-col>
      @if(loadingSelectCompany){
      <div class="spinner-container">
        <ion-spinner name="dots"> </ion-spinner>
      </div>
      } @else{ @if(selectedCompany){
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="text-center">
          @if(checkUserByCompany){
          <div class="m-4">
            Este usuario ya pertenece a la empresa seleccionada como
            <strong>{{userTypesAsociate}}</strong>.
          </div>
          } @else{
          <div class="m-4">
            El usuario no pertenece a la empresa seleccionada.
          </div>
          }
          <div class="m-3">
            <strong
              >Seleccione el tipo de usuario que desea agregar a la
              empresa:</strong
            >
          </div>

          <div class="options-container">
            <p-selectButton
              [(ngModel)]="userTypeSelected"
              [options]="userTypes"
              optionLabel="label"
              optionValue="value"
              (click)="selectUserType($event, userTypeSelected)"
            />
          </div>
        </div>
      </ion-col>
      @if(typeSelected){ @if(loadingDepartments){
      <div class="spinner-container">
        <ion-spinner name="dots"> </ion-spinner>
      </div>
      }@else{ @if(userTypeSelected == "EMPLOYEE"){
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-dropdown
          [(ngModel)]="selectedDepartment"
          [options]="allDepartments"
          appendTo="body"
          optionLabel="main_activity"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          optionValue="id"
          placeholder="Selecciona un departamento"
        />
      </ion-col>
      }
      <ion-col size-xs="12" size-sm="12" size-md="12" class="m-4">
        <div class="text-center">
          <p-button
            [loading]="loadingAddUserToCompany"
            label="Vincular usuario"
            icon="pi pi-check"
            (click)="saveUserToCompany()"
            class="p-button-success associate-user"
          />
        </div>
      </ion-col>
      } } } }
    </ion-row>
  </ion-grid>
</p-dialog>

<!-- Modal para asignar permisos en secciones a un usuario -->
<p-dialog
  header="{{'Añadir permisos de acceso a secciones para: ' + selectedUser?.name}}"
  [(visible)]="dialogAddSections"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
  (onHide)="closeSectionsModal()"
>
  @if(sections){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        @if(companiesByUser.length === 0){ 
          El usuario no pertenece a ninguna empresa. Debe
        <strong>vincular al usuario al menos con una empresa</strong>, para
        poder asignarle permisos. 
        }@else{
        <!-- Seleccionar company -->
        <p-dropdown
          [(ngModel)]="selectedCompany"
          [options]="companiesByUser"
          appendTo="body"
          optionLabel="company_name"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          optionValue="id"
          placeholder="Selecciona una empresa"
          (onChange)="handleSelectedCompany($event)"
        />
        }
      </ion-col>

      @if(selectedCompany){ 
        @if(loadingSelectCompany){
      <div class="spinner-container">
        <ion-spinner name="dots"> </ion-spinner>
      </div>
        } @else{ 
          @if(!checkPermissionsByUser){
      <!-- Seleccionar rol -->
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="text-center">
          <div class="m-4">
            El usuario no tiene ningún permiso en la empresa seleccionada.
          </div>
          <div class="m-3">
            <strong>Seleccione el tipo de usuario que desea asignar:</strong>
          </div>
        </div>
        <div class="options-container">
          <p-selectButton
            [options]="roleTypes"
            [(ngModel)]="roleTypeSelected"
            optionLabel="label"
            optionValue="value"
            (click)="handleClickAddPermissionByRoleUser($event, roleTypeSelected)"
          />
        </div>
      </ion-col>
          @if(rolSelected){
      <ion-grid>
        <ion-row class="m-4">
          <ion-col class="text-center">
            <p>
              ¿Está seguro que desea asignar permisos de
              <strong>&nbsp;{{ roleTypeSelected }}&nbsp;</strong> al usuario?
            </p>
            <p-button
              class="m-2"
              label="Cancelar"
              severity="secondary"
              (click)="handleClickAddPermissionByRoleUser($event, null)"
            />
            <p-button
              icon="pi pi-save"
              label="Guardar"
              (click)="savePermissionByRoleUser(roleTypeSelected)"
              [loading]="loadingSavePermissionByRol"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
          } 
        } @else{
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="text-center">
          <div class="m-4">
            Este usuario ya tiene permisos creados para esta empresa.
          </div>
          <div class="m-3">
            <strong
              >Seleccione la sección y los permisos que desea asignar:</strong
            >
          </div>
        </div>
        <p-dropdown
          [(ngModel)]="selectedSection"
          [options]="sections"
          appendTo="body"
          optionLabel="name"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          optionValue="id"
          placeholder="Selecciona una sección"
        >
        </p-dropdown>
      </ion-col>
          @if(selectedSection){
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-multiSelect
          placeholder="Selecciona permisos"
          [(ngModel)]="selectedPermissions"
          [options]="permissions"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          class="multiselect-form"
          appendTo="body"
          optionLabel="name"
          optionValue="id"
        />
      </ion-col>
      <!---button-->
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-button
          [disabled]="selectedSection === 0 && !selectedCompany"
          [loading]="loadingForm"
          label="Guardar"
          (click)="onSubmitUserPermissions()"
          class="p-button-success associate-user"
        />
      </ion-col>
          } <!--- section selected -->
        } <!--- have permission -->
      } <!---not loading -->
    } <!--- company selected -->
    </ion-row>
  </ion-grid>
  } <!--- sections exists -->
</p-dialog>

<!-- Modal para visualizar permisos en secciones ya asignados -->
<p-dialog
  header="{{'Gestión de permisos de acceso a secciones para: ' + selectedUser?.name}}"
  [(visible)]="dialogViewSections"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
  (onHide)="closeRegisteredSectionsModal()"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        @if(companiesByUser.length === 0){ El usuario no pertenece a ninguna
        empresa. Debe
        <strong>vincular al usuario al menos con una empresa</strong>, para
        poder visualizar sus permisos. }@else{
        <!-- Seleccionar company -->
        <p-dropdown
          [(ngModel)]="selectedCompany"
          [options]="companiesByUser"
          appendTo="body"
          optionLabel="company_name"
          [style]="{'width':'100%'}"
          [inputStyle]="{'width':'100%'}"
          optionValue="id"
          placeholder="Selecciona una empresa"
          (onChange)="handleSelectedCompany($event)"
        />
        }
      </ion-col>

      @if(flagCompany && transformedSections){
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <p-table
          #dt1
          styleClass="p-datatable-striped"
          responsiveLayout="scroll"
          [value]="transformedSections"
          [paginator]="true"
          [rows]="5"
          [globalFilterFields]="['name']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Sección</th>
              <th>Permiso</th>
              <th>Opciones</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-section>
            <tr>
              <td>{{ section.sectionName }}</td>
              <td>{{ section.permissionName }}</td>
              <td>
                <button
                  pButton
                  type="button"
                  icon="pi pi-trash"
                  pTooltip="Eliminar permiso"
                  class="m-1 p-button-danger"
                  (click)="onDeletePermissionClick($event, section)"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">
                No se encontraron permisos, seleccione una empresa
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ion-col>
      }
    </ion-row>
  </ion-grid>
</p-dialog>
